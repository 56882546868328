<template>
  <div class="row product-tiles">
    <div class="col-sm-6 col-xs-12 pb15">
      <router-link :to="partnerTiles['delivery_methods'].link">
        <icon-link-tile class="tile-default-coloring">
          <template slot="icon">
            <svg
              class="tile-icon mr25"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 75 91"
            >
              <path d="M49 90.8a3.3 3.3 0 01-3.2-3.3V59.4h-16v28.1a3.3 3.3 0 01-3.2 3.3H3.9a3.2 3.2 0 01-3.3-3.3v-50c0-.9.4-1.7 1-2.3l33.9-34a3.2 3.2 0 014.6 0l34 34c.5.6.9 1.4.9 2.3v50a3.2 3.2 0 01-3.3 3.3zm3.3-6.5h16.2V38.8L37.8 8.1 7.1 38.8v45.5h16.2V56a3.3 3.3 0 013.3-3.2H49a3.3 3.3 0 013.3 3.2z" />
            </svg>
          </template>
          <template slot="title">
            {{ $t(partnerTiles['delivery_methods'].title) }}
          </template>
        </icon-link-tile>
      </router-link>
    </div>

    <div class="col-sm-6 col-xs-12 pb15">
      <router-link :to="partnerTiles['contacts'].link">
        <icon-link-tile class="tile-default-coloring">
          <template slot="icon">
            <svg
              class="tile-icon mr25"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 67 92"
            >
              <path d="M31 89.8c-.8-1.1-18.5-27.2-25-38.1A33 33 0 0133.8.7a33 33 0 0127.7 51c-6.6 10.9-24.3 37-25 38.1a3.3 3.3 0 01-5.5 0zm-23.7-56c0 4.8 1.5 9.7 4.3 14.4 5 8.2 16.8 25.8 22.1 33.8 5.4-8 17.1-25.6 22-33.8A26.4 26.4 0 107.3 33.7z" /><path d="M17 33.7a16.7 16.7 0 1133.4 0 16.7 16.7 0 01-33.3 0zm6.7 0a10 10 0 1020 0 10 10 0 00-20 0z" />
            </svg>
          </template>
          <template slot="title">
            {{ $t(partnerTiles['contacts'].title) }}
          </template>
        </icon-link-tile>
      </router-link>
    </div>
  </div>
</template>

<script>
import IconLinkTile from 'theme/components/theme/anyrent/IconLinkTile';
import config from 'config'

export default {
  components: { IconLinkTile },
  computed: {
    partnerTiles () {
      return (this.$ssrContext?.partnerData || config.partnerData)?.data?.product?.product_detail_tiles;
    }
  }
};
</script>

<style lang="scss">
// Temporarily hiding product-tiles (AR-1856)
.product-tiles {
  display: none;
}

.product-tiles .tile-clr-bg {
  padding-top: 48%;
}

.tile-default-coloring {
  background-color: #b5ab9e;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}
</style>
