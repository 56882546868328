<template>
  <button class="back-button">
    <div class="back-button-content">
      <div class="arrow-wrapper">
        <ArrowIcon stroke="white" class="arrow" />
      </div>
      <div class="span-wrapper">
        <span class="text">{{ text }}</span>
      </div>
    </div>
  </button>
</template>

<script>
import ArrowIcon from './ArrowIcon';

export default {
  components: {
    ArrowIcon
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-white: color(white);
$color-light-gray: color(light-gray);

.back-button {
  padding: 10px;
  background-color: $color-light-gray;
  border-radius: 38px;
  border: 0px;
}
.back-button-content {
  display: flex;
  height: 22px;
  gap: 4px;
}
.arrow {
  justify-self: start;
  padding-left: 10px;
}
.arrow-wrapper {
  height: 100%;
  padding-bottom: 3px;
  padding-top: 3px;
}
.span-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.text {
  font-family: TTCommons-Medium, sans-serif;
  color: $color-white;
  font-size: 18px;
  justify-self: center;
  padding-left: 12px;
  padding-right: 10px;
}

</style>
