export default {
  data () {
    return { visibleMap: false };
  },
  methods: {
    toggleMap () {
      this.visibleMap = !this.visibleMap;
    }
  }
};
