<template>
  <div>
    <transition name="fade">
      <div class="mb20 price" v-if="intervalPrice !==''">
        <div
          class="h2 mb20 cl-mine-shaft weight-700"
        >
          <span class="price-details from">{{ $t('Your calculation') }}:</span><span class="price-number calculated">{{ intervalPrice | price(storeView) }}</span><span class="price-details">/ vč. DPH </span>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { CartService } from '@vue-storefront/core/data-resolver'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductIntervalPrice',
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    quantity: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      intervalPrice: '',
      error: ''
    }
  },
  computed: {
    ...mapGetters({
      userToken: 'user/getToken',
      cartId: 'cart/getCartToken'
    }),
    storeView () {
      return currentStoreView()
    },
    dateFrom: {
      get () {
        return this.$store.state.reservations.dateFrom
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateFrom', date)
      }
    },
    dateTo: {
      get () {
        return this.$store.state.reservations.dateTo || '';
      },
      set (date) {
        return this.$store.dispatch('reservations/setDateTo', date)
      }
    }
  },
  async mounted () {
    this.getIntervalPrice()
  },
  methods: {
    async getIntervalPrice () {
      this.$emit('error', null)
      this.intervalPrice = ''
      if (this.dateFrom !== '' && this.dateTo !== '') {
        // sku, from, to, token/cartId
        const from = this.dateFrom;
        const to = this.dateTo;
        let token = ''
        // token
        if (this.userToken === '') {
          token = this.cartId
        } else {
          token = this.userToken
        }
        const data = await CartService.intervalPrice(this.product.sku, this.quantity, from, to, token)
        const result = data?.result || null;
        if (!result) {
          this.$emit('error', result)
          return;
        }
        if (result.price) {
          this.intervalPrice = result.price
        } else if (result.price === 0) {
          this.$emit('error', this.$t('Missing price'))
        }
      } else {
        this.intervalPrice = ''
      }
    }
  },
  watch: {
    dateFrom (val) {
      // this.getIntervalPrice()
    },
    dateTo (val) {
      // this.getIntervalPrice()
    },
    product () {
      this.getIntervalPrice()
    },
    quantity () {
      this.getIntervalPrice()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-primary: color(primary);
// .price-original {
//   text-decoration: line-through;
// }

.price {
  @media (max-width: 767px) {
    color: $color-primary;
  }
}
.price-details {
  font-size: 20px;
  font-family: 'TTCommons-Light';
}
.price-details.from {
  padding-right: 0.3em;
}
.price-number {
  font-family: 'TTCommons-Bold';
}
.fade-enter-active {
  transition: all .9s;
}
.fade-leave-active {
  transition: all 0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
