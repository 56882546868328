<template>
  <modal name="modal-pricelist" :width="620">
    <div slot="header">
      {{ $t('Rental price list') }}
    </div>
    <div slot="uppertext">
      <span>Každé vybavení si můžete zapůjčit na kratší nebo delší dobu.
        Podle délky zápůjčky se může lišit cena půjčovného za jedinný den a následně celková cena výpůjčky může být velice příznivá, při zapůjčení na delší období.
        U vybraného vybavení poskytujeme zvýhodněné půjčovné na víkend, delší období nebo i celou sezónu.
      </span>
      <br>
      <br>
      <span>
        {{ $t('Current pricelist for selected equipment: ') }}
      </span>
      <br>
    </div>
    <div class="mb20 text-gray" slot="content">
      <div v-if="priceList && priceList.length > 0">
        <table style="width:100%" class="pb20" v-if="priceListPerDay.length > 0">
          <tr>
            <td class="col1 bold pb10">
              {{ $t('Total amount of rental days') }}
            </td>
            <td class="col1 bold pb10">
              {{ $t('Rental price') }}
            </td>
          </tr>
          <tr v-for="price in priceListPerDay" :key="price.id">
            <td class="col1" v-if="price.numberOfDays > 1">
              od {{ price.numberOfDays }} dní
            </td>
            <td class="col1" v-else>
              od {{ price.numberOfDays }} dne
            </td>
            <td class="col1">
              {{ price.price }},- Kč / den
            </td>
          </tr>
        </table>
        <table style="width:100%" class="pb40" v-if="priceListFixed.length > 0 ">
          <tr>
            <td class="col1 bold pb10">
              {{ $t('Special offers') }}
            </td>
            <td class="col1 bold pb10">
              {{ $t('Rental price') }}
            </td>
          </tr>
          <tr v-for="price in priceListFixed" :key="price.id">
            <td class="col1" v-if="price.intervalEnd === 1">
              {{ price.gaps }} den
            </td>
            <td class="col1" v-else-if="price.intervalEnd > 4">
              {{ price.gaps }} dní
            </td>
            <td class="col1" v-else>
              {{ price.gaps }} dny
            </td>
            <td class="col1">
              {{ price.price }},- Kč / období
            </td>
          </tr>
        </table>
        <span class="note mb20">{{ $t('All prices include VAT.') }}</span>
      </div>
      <div class="mb20" v-else>
        <span class="note cl-anyrent">{{ $t('Problem with downloading the price list, try again please') }}</span>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import Modal from 'theme/components/core/ModalTwo'

export default {
  name: 'PriceList',
  computed: {
    ...mapState({
      activeElem: state => state.ui.authElem,
      priceList: state => state.product.priceList
    }),
    ...mapGetters({
      product: 'product/getCurrentProduct'
    }),
    priceListFixed () {
      const fixedOffers = []
      for (const price of this.priceList) {
        if (price.type === 'fixed') {
          const newPrice = {...price}
          const interval = this.scanPricelist(price)
          newPrice.interval = interval
          newPrice.intervalEnd = price.numberOfDays + interval
          if (interval > 0) {
            newPrice.gaps = `${price.numberOfDays} - ${price.numberOfDays + interval}`
          } else { newPrice.gaps = `${price.numberOfDays}` }

          fixedOffers.push(newPrice)
        }
      }
      return fixedOffers
    },
    priceListPerDay () {
      const dayOffers = []
      for (const price of this.priceList) {
        if (price.type === 'perDay') {
          dayOffers.push(price)
        }
      }
      return dayOffers
    }
  },
  components: {
    Modal
  },
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-sizeguide')
    },
    scanPricelist (compared) {
      // figure out what's the highest amount of days/
      let highestPosition = 0;
      for (const price of this.priceList) {
        if (price.numberOfDays > highestPosition) {
          highestPosition = price.numberOfDays
        }
      }

      const position = compared.numberOfDays + 1
      let match = false
      let gap = 0;

      for (let pos = position; pos < highestPosition + 1; pos++) {
        // check all prices
        // find the one where the number of days is the same
        for (const price of this.priceList) {
          if (price.numberOfDays === pos) {
            // stop the loop
            match = true
            break
          }
        }
        if (match) {
          break
        }
        // record the gap
        gap++
      }
      return gap
    }
  }
}
</script>

<style scoped>
    .header {
      background: #fff;
    }
    .content.grey {
      background-color: rgb(255, 249, 249);
    }
    .text-gray {
      font-size: 18px;
    }
    .modal {
        font-size: 16px;
    }
    .modal-content {
        max-height: 80%;
    }
    .col1 {
      width: 50%;
      font-family: 'TTCommons-Light';
    }
    .col1.bold {
      font-family: 'TTCommons-Medium';
      color: #1d1d1d;
      margin-bottom: 3px;
    }
    .note {
      font-size:16px;
    }
</style>
